import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import styles from './AddProduct.module.css';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [originalPrice, setOriginalPrice] = useState('');
  const [sale, setSale] = useState(false);
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://api.mufcredbrigade.com/api/add-product',
        { name, price, originalPrice, sale, image, category },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      Swal.fire('Success', 'Product added successfully', 'success');

      // Reset form
      setName('');
      setPrice('');
      setOriginalPrice('');
      setSale(false);
      setImage(null);
      setCategory('');
    } catch (error) {
      console.error('Failed to add product:', error);
      Swal.fire('Error', 'Failed to add product', 'error');
    }
  };

  return (
    <div className={styles.addProductContainer}>
      <h1>Add New Product</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label>
          Original Price:
          <input
            type="number"
            name="originalPrice"
            value={originalPrice}
            onChange={(e) => setOriginalPrice(e.target.value)}
            required
          />
        </label>
        <label>
          Price (Optional):
          <input
            type="number"
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </label>
        <label>
          Category:
          <input
            type="text"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </label>
        <label>
          On Sale:
          <input
            type="checkbox"
            name="sale"
            checked={sale}
            onChange={(e) => setSale(e.target.checked)}
          />
        </label>
        <label>
          Product Image:
          <input
            type="file"
            name="image"
            onChange={(e) => setImage(e.target.files[0])}
            required
          />
        </label>
        <button type="submit" className={styles.submitButton}>
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;