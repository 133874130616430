import React from 'react';

const AddAdminComponent = () => {
  return (
    <div>
      <h2>Add Admin</h2>
      {/* Add Admin content */}
    </div>
  );
};

export default AddAdminComponent;