import React from 'react';
import styles from './Sidebar.module.css';

const Sidebar = ({ setActiveComponent, activeComponent }) => {
  return (
    <div className={styles.sidebar}>
      <button 
        onClick={() => setActiveComponent('dashboard')} 
        className={activeComponent === 'dashboard' ? styles.activeButton : styles.dashboard }
      >
        Dashboard
      </button>
      {/* <button 
        onClick={() => setActiveComponent('add product')} 
        className={activeComponent === 'add product' ? styles.activeButton : ''}
      >
        Add Product
      </button>
      <button 
        onClick={() => setActiveComponent('all product')} 
        className={activeComponent === 'all product' ? styles.activeButton : ''}
      >
        All Product
      </button> */}
      <button 
        onClick={() => setActiveComponent('members')} 
        className={activeComponent === 'members' ? styles.activeButton : ''}
      >
        Members
      </button>
      <button 
        onClick={() => setActiveComponent('donations')} 
        className={activeComponent === 'donations' ? styles.activeButton : ''}
      >
        Donations
      </button>
      <button 
        onClick={() => setActiveComponent('admins')} 
        className={activeComponent === 'admins' ? styles.activeButton : ''}
      >
        Admins
      </button>
      <button 
        onClick={() => setActiveComponent('blogs')} 
        className={activeComponent === 'blogs' ? styles.activeButton : ''}
      >
        Blogs
      </button>
      <button 
        onClick={() => setActiveComponent('addBlog')} 
        className={activeComponent === 'addBlog' ? styles.activeButton : ''}
      >
        Add Blog
      </button>
      <button 
        onClick={() => setActiveComponent('addAdmin')} 
        className={activeComponent === 'addAdmin' ? styles.activeButton : ''}
      >
        Add Admin
      </button>
      <button 
        onClick={() => setActiveComponent('add committee')} 
        className={activeComponent === 'add committee' ? styles.activeButton : ''}
      >
        Add Committee
      </button>
      <button 
        onClick={() => setActiveComponent('all committees')} 
        className={activeComponent === 'all committees' ? styles.activeButton : ''}
      >
        All Committees
      </button>
      <button 
        onClick={() => setActiveComponent('add board')} 
        className={activeComponent === 'add board' ? styles.activeButton : ''}
      >
       Add Board
      </button>
      <button 
        onClick={() => setActiveComponent('all board')} 
        className={activeComponent === 'all board' ? styles.activeButton : ''}
      >
       All Boards
      </button>
      
      <button 
        onClick={() => setActiveComponent('all members')} 
        className={activeComponent === 'all members' ? styles.activeButton : ''}
      >
        All Members
      </button>
    </div>
  );
};

export default Sidebar;
