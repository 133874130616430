import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import styles from './AllProducts.module.css';

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.mufcredbrigade.com/api/get-all-products');
        setProducts(response.data.products);
      } catch (error) {
        console.error('Error fetching products:', error);
        Swal.fire('Error', 'Failed to fetch products', 'error');
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://api.mufcredbrigade.com/api/delete-product/${id}`);
          setProducts(products.filter((product) => product._id !== id));
          Swal.fire('Deleted!', 'Product has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting product:', error);
          Swal.fire('Error', 'Failed to delete product', 'error');
        }
      }
    });
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditModalOpen(true);
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
    setSelectedProduct(null);
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedProduct({
      ...selectedProduct,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.mufcredbrigade.com/api/edit-product/${selectedProduct._id}`, selectedProduct);
      setProducts(products.map((product) => (product._id === selectedProduct._id ? selectedProduct : product)));
      handleModalClose();
      Swal.fire('Success', 'Product updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update product:', error);
      Swal.fire('Error', 'Failed to update product', 'error');
    }
  };

  return (
    <div className={styles.allProductsContainer}>
      <h1>All Products</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Original Price</th>
            <th>Category</th>
            <th>On Sale</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product._id}>
              <td>{product.name}</td>
              <td>{product.price}</td>
              <td>{product.originalPrice}</td>
              <td>{product.category}</td>
              <td>{product.sale ? 'Yes' : 'No'}</td>
              <td className={styles.actions}>
                <FaEdit className={styles.editIcon} onClick={() => handleEdit(product)} />
                <FaTrashAlt className={styles.deleteIcon} onClick={() => handleDelete(product._id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h2>Edit Product</h2>
            <form onSubmit={handleEditSubmit} className={styles.form}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={selectedProduct.name}
                  onChange={handleEditChange}
                  required
                />
              </label>
              <label>
                Price:
                <input
                  type="number"
                  name="price"
                  value={selectedProduct.price}
                  onChange={handleEditChange}
                  required
                />
              </label>
              <label>
                Original Price:
                <input
                  type="number"
                  name="originalPrice"
                  value={selectedProduct.originalPrice}
                  onChange={handleEditChange}
                />
              </label>
              <label>
                Category:
                <input
                  type="text"
                  name="category"
                  value={selectedProduct.category}
                  onChange={handleEditChange}
                  required
                />
              </label>
              <label className={styles.sale}>
                On Sale:
                <input
                  type="checkbox"
                  name="sale"
                  checked={selectedProduct.sale}
                  onChange={handleEditChange}
                />
              </label>
              <div className={styles.allProductsBtns}>
                <button type="submit" className={styles.submitButton}>
                  Save Changes
                </button>
                <button type="button" onClick={handleModalClose} className={styles.cancelButton}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;