import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import styles from './AllCommittees.module.css';

const AllCommittees = () => {
  const [committees, setCommittees] = useState([]);
  const [selectedCommittee, setSelectedCommittee] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    const fetchCommittees = async () => {
      try {
        const response = await axios.get('https://api.mufcredbrigade.com//api/get-all-committee');
        setCommittees(response.data.committees);
      } catch (error) {
        console.error('There was an error fetching the committees!', error);
      }
    };
    fetchCommittees();
  }, []);

  const handleEditClick = (committee) => {
    setSelectedCommittee(committee);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (committeeId) => {
    setSelectedCommittee(committeeId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`https://api.mufcredbrigade.com/api/delete-committee/${selectedCommittee}`).then((res) => {
        setCommittees(committees.filter((c) => c._id !== selectedCommittee._id));
      setIsDeleteModalOpen(false);
      console.log(res)
      })
      
    } catch (error) {
      console.error('There was an error deleting the committee member!', error);
    }
  };

  return (
    <div className={styles.container}>
      <h2>All Committees</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {committees.map((committee) => (
            <tr key={committee._id}>
              <td>{committee.name}</td>
              <td>{committee.title}</td>
              <td>{committee.details}</td>
              <td>
                <FaEdit onClick={() => handleEditClick(committee._id)} className={styles.icon} />
                <FaTrash onClick={() => handleDeleteClick(committee._id)} className={styles.icon} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isEditModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Edit Committee Member</h2>
            {/* Include form to edit the selected committee member */}
            <button onClick={() => setIsEditModalOpen(false)}>Close</button>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Are you sure you want to delete this member?</h2>
            <button onClick={handleDeleteConfirm}>Delete</button>
            <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCommittees;