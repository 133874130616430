import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './AllMembers.module.css';

const AllMembers = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get('https://api.mufcredbrigade.com//api/get-all-members');
        setMembers(response.data.members);
        console.log(response.data)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className={styles.allMembers}>
      <h1>All Members</h1>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Date of Birth</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Country Code</th>
            <th>Residential Address</th>
            <th>Country</th>
            <th>City</th>
            <th>Occupation</th>
            <th>Company Name</th>
            <th>Work Address</th>
            <th>Membership Type</th>
            <th>Membership Duration</th>
            <th>Chapter</th>
            <th>Leadership Position</th>
            <th>Manchester United Membership ID</th>
            <th>Heard About Us</th>
            <th>Reason for Joining</th>
            <th>Agreement</th>
            <th>Privacy Policy</th>
            <th>Signature</th>
            <th>Date</th>
            <th>Date of Expiry</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member._id}>
              <td className={styles.name} >{member.fullName}</td>
              <td>{new Date(member.dateOfBirth).toLocaleDateString()}</td>
              <td>{member.gender}</td>
              <td>{member.email}</td>
              <td>{member.phoneNumber}</td>
              <td>{member.countryCode}</td>
              <td>{member.residentialAddress}</td>
              <td>{member.country}</td>
              <td>{member.city}</td>
              <td>{member.occupation}</td>
              <td>{member.companyName}</td>
              <td>{member.workAddress}</td>
              <td>{member.membershipType}</td>
              <td>{member.membershipDuration}</td>
              <td>{member.chapter}</td>
              <td>{member.leadershipPosition}</td>
              <td>{member.manUtdMembershipID}</td>
              <td>{member.heardAboutUs}</td>
              <td>{member.reasonForJoining}</td>
              <td>{member.agreement ? 'Yes' : 'No'}</td>
              <td>{member.privacyPolicy ? 'Yes' : 'No'}</td>
              <td>{member.signature}</td>
              <td>{new Date(member.date).toLocaleDateString()}</td>
              <td>{new Date(member.dateOfExpiry).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllMembers;