import React, { useState } from 'react';
import axios from 'axios';
import styles from './AddBoard.module.css';

const AddBoard = () => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
//   const [image, setImage] = useState(null);

  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const formData = new FormData();
    // formData.append('name', name);
    // formData.append('title', title);
    // formData.append('details', details);
    // formData.append('image', image);

    try {
      const response = await axios.post('https://api.mufcredbrigade.com/api/add-board', { name, title, location }/*, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }*/);
      console.log(response)
      if(response.status === 200) {
        alert("Board member added")
        setLocation("")
    //   setImage("")
      setName("")
      setTitle("")
      }
      
    } catch (error) {
      console.error('There was an error adding the committee member!', error);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Add Committee Member</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label>Name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className={styles.inputGroup}>
          <label>Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className={styles.inputGroup}>
          <label>Location</label>
          <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
        
        </div>
        <button type="submit">Add Board</button>
      </form>
    </div>
  );
};

export default AddBoard;
