import React, { useState } from 'react';
import axios from 'axios';
import styles from './AddCommittee.module.css';

const AddCommittee = () => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.mufcredbrigade.com/api/add-committee', { name, title, location }, /*{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }*/);
      console.log(response)
      if(response.status === 200) {
        alert("Committee added")
        setLocation("")
      // setImage("")
      setName("")
      setTitle("")
      }
      
    } catch (error) {
      console.error('There was an error adding the committee member!', error);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Add Committee Member</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label>Name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className={styles.inputGroup}>
          <label>Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className={styles.inputGroup}>
          <label>Location</label>
          <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
        
        </div>
        <button type="submit">Add Member</button>
      </form>
    </div>
  );
};

export default AddCommittee;
