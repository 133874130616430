import React from 'react';

const AddBlogComponent = () => {
  return (
    <div>
      <h2>Add Blog</h2>
      {/* Add Blog content */}
    </div>
  );
};

export default AddBlogComponent;
